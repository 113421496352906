class EventTypeSelectWatcher {
	constructor() {
		this.eventTypeSelect = document.querySelector("#event-type-select");
		if (this.eventTypeSelect) {
			this.run();
		}
	}

	run() {
		this.eventTypeSelect.addEventListener("change", (event) => {
			console.log(event);
			const option = this.eventTypeSelect.querySelector(`option[value="${event.target.value}"]`);
			const target = option.getAttribute("data-target");
			console.log(option);

			if (target === "_blank") {
				window.open(event.target.value, option.getAttribute("data-target"));
			} else {
				window.location.href = event.target.value;
			}
		});
	}
}

export default EventTypeSelectWatcher;
