class SuperActiveAnchor {
	constructor() {
		console.log("SuperActiveAnchor script is running...");

		this.anchors = document.querySelectorAll("a");
		//console.log(this.anchors);
		if (this.anchors) {
			this.run();
		}
	}

	run() {
		this.anchors.forEach((anchor) => {
			const host = window.location.host;
			const path = window.location.pathname;
			const href = window.location.href;
			const hash = window.location.hash;

			// Check to make sure it's an internal link
			if (anchor.host === host) {
				// check if href matches the page
				if (anchor.href === href) {
					anchor.setAttribute("data-s-active-anchor", "");
				}
			}
		});
	}
}

export default SuperActiveAnchor;
