class Globals {

    constructor() {
        const wicLink = document.querySelector('.wic-apply-button a');
		if (wicLink) {
			wicLink.addEventListener("click", (e) => {
				gtag('event', 'click', {
					'event_category': 'button-click',
					'event_label': 'WIC Apply Button',
					'value': 'clicked'
				});
			});
		}
    }

}

export default Globals;
